import { useApiGetMany, useApiPost, useApiGet, useApiDelete } from '@papertrail/web3-utils'
import { Account, Filter, Subscription } from './types'

export function accountMapper(data): Account {
  // if the account is returned without member data, we must have employee access
  return {
    id: data.id,
    active: !data.deactivated_at,
    accountName: data.name,
    templateName: data.template ? data.template.data.name : '',
    isDemo: data.demo,
    logo: data.logo,
    isAdmin: (data.member && data.member.data.admin) || false,
    isEmployee: !!!data.member,
    member: data.member ? data.member.data : undefined,
    expiresAt: data.trial_expires_at ? data.trial_expires_at : undefined,
    deactivatedAt: data.deactivated_at ? data.deactivated_at : undefined,
    subscription: data.subscription ? subscriptionMapper(data.subscription.data) : undefined
  }
}

export function useApiActivateCalendar(accountId: string, memberId: string) {
  const [activateState, post] = useApiPost(`/accounts/${accountId}/members/${memberId}/calendars`, (data) => data)

  function postActivate(calendarName: string, monthsToDisplay: number, threshold: number) {
    const payload = {
      calendar_name: calendarName,
      search_period_months: monthsToDisplay,
      collate_record_threshold: threshold
    }

    post(payload)
  }

  return [activateState, postActivate] as const
}

export function useApiGetCalendar(accountId: string, memberId: string, calendarSubscriptionId: string) {
  const [state, get] = useApiGet(
    `/accounts/${accountId}/members/${memberId}/calendars/${calendarSubscriptionId}`,
    (data) => data
  )

  function getCalendar() {
    get({})
  }

  return [state, getCalendar]
}

export function useApiUpdateCalendar(accountId: string, memberId: string, calendarSubscriptionId) {
  const [activateState, post] = useApiPost(
    `/accounts/${accountId}/members/${memberId}/calendars/${calendarSubscriptionId}`,
    (data) => data
  )

  function postUpdate(calendarName: string, monthsToDisplay: number, threshold: number) {
    const payload = {
      calendar_name: calendarName,
      search_period_months: monthsToDisplay,
      collate_record_threshold: threshold
    }
    post(payload)
  }

  return [activateState, postUpdate] as const
}

export function useApiRemoveCalendar(accountId: string, memberId: string, calendarSubscriptionId: string) {
  const [activateState, del] = useApiDelete(
    `/accounts/${accountId}/members/${memberId}/calendars/${calendarSubscriptionId}`,
    (data) => data
  )

  function removeClendar() {
    del({})
  }

  return [activateState, removeClendar] as const
}

export function useCloneAccount() {
  const [cloneAccountState, post] = useApiPost(`/accounts`, (data) => data)

  function postCloneAccount(
    accountId: string,
    accountName: string,
    subscriptionId: string,
    demo: boolean,
    includeFolders: boolean,
    includeRecords: boolean
  ) {
    const payload = {
      account_id: accountId,
      name: accountName,
      subscription_id: subscriptionId,
      demo,
      with_folders: includeFolders,
      with_records: includeFolders && includeRecords
    }
    post(payload)
  }

  return [cloneAccountState, postCloneAccount] as const
}

export function useApiGetReports(accountId: string) {
  return useApiGet(`/accounts/${accountId}/analytic/reports`, (data) => data.data)
}

export function useApiGetAccount(id: string) {
  const [accountState, getAccount] = useApiGet(`/accounts/${id}`, (data) => data)
  return [accountState, getAccount] as const
}

export function useApiGetMembers(accountId: string) {
  const [membersState, getMembers] = useApiGet(`/accounts/${accountId}/members`, (data) => data.data)

  function loadMembers() {
    getMembers({
      include: 'user,member_calendar'
    })
  }

  return [membersState, loadMembers] as const
}

export function useApiGetAccounts(pageSize: number) {
  const [accountsState, loadFirstPage, loadMoreAccounts] = useApiGetMany<Account>(`/accounts`, pageSize, accountMapper)
  function loadAccounts(extraParams, deactivatedOnly: boolean) {
    const includes = deactivatedOnly
      ? ' v,deactivated_only'
      : 'member,subscription,subscription.package,subscription.features,template,member.user,member.member_calendar'

    loadFirstPage({
      include: includes,
      role: 'member,employee',
      sort: 'name',
      ...extraParams
    })
  }

  return [accountsState, loadAccounts, loadMoreAccounts] as const
}

export function useApiGetRecentlyDeactivatedAccounts() {
  const [accountsState, loadFirstPage] = useApiGetMany<Account>(`/accounts`, 1, accountMapper)

  function loadAccounts() {
    loadFirstPage({
      include: 'deactivated_only',
      role: 'member',
      sort: 'name'
    })
  }

  return [accountsState, loadAccounts] as const
}

export function useApiActivateAccount(accountId: string) {
  const [activateAccountState, postActivateAccount] = useApiPost(
    `/accounts/${accountId}/actions/convert-demo`,
    (data) => data
  )

  function activateAccount() {
    postActivateAccount({ demo: false })
  }

  return { activateAccountState, activateAccount }
}

function subscriptionMapper(data): Subscription {
  return {
    id: data.id,
    subName: data.name,
    active: data.active,
    planName: data.package.data.code === 'base-package' ? '' : data.package.data.name,
    isTrial: data.package.data.code === 'base-package',
    deactivatedAt: data.deactivated_at ? data.deactivated_at : undefined,
    allowances: {
      users: data.members,
      records: data.records,
      unlimitedRecords: data.package.data.unlimited_records,
      viewonlyUsers: data.view_only_members,
      unlimitedViewOnlyUsers: data.view_only_members >= 1000
    },
    features: data.features.data,
    trialExpiry: data.trial_expires_at
  }
}

export function useApiGetSubscription(pageSize: number) {
  const [subscriptionsState, loadFirstPage, loadMoreSubscriptions] = useApiGetMany<Subscription>(
    `/subscriptions`,
    pageSize,
    subscriptionMapper
  )

  function loadSubscriptions(extraParams, deactivatedOnly: boolean) {
    const includes = deactivatedOnly ? 'package,deactivated_only,features' : 'package,features'

    loadFirstPage({
      include: includes,
      sort: 'name',
      ...extraParams
    })
  }

  return [subscriptionsState, loadSubscriptions, loadMoreSubscriptions] as const
}

function filtersMapper(data): Filter {
  return {
    id: data.id,
    name: data.name,
    profile: data.profile,
    count: data.count
  }
}

export function useApiGetAccountFilters(accountId: string, pageSize: number) {
  const [filtersState, loadFirstPage, loadMoreFilters] = useApiGetMany(
    `/accounts/${accountId}/filters`,
    pageSize,
    filtersMapper
  )

  function loadFilters() {
    loadFirstPage({
      include: 'count,descendants'
    })
  }

  return [filtersState, loadFilters, loadMoreFilters] as const
}
