import React from 'react'
import { Account, Filter } from '../types'
import { FetchManyState } from '@papertrail/web3-utils'
import AccountStatusLabel from './AccountStatusLabel'
import AccountAvatar from './AccountAvatar'
import { Card, CardHeader, CardContent, Stack, Box, ParaSmall } from '@papertrail/styleguide'
import { FilterDisplay } from './FilterDisplay'
import slugify from 'slugify'
import AccountActions from './AccountActions'

type Props = {
  account: Account
  filtersState: FetchManyState<Filter>
  onClick: () => void
  reloadAccounts: () => void
  demoActivated: (error?: string) => void
}

const styles = {
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  }
}

export default function AccountCard(props: Props) {
  const { account, filtersState, onClick, demoActivated, reloadAccounts } = props
  return (
    <Card
      variant="outlined"
      data-cy={'account-card'}
      data-testid={'account-card-' + slugify(account.accountName.toLowerCase())}
      sx={styles.card}
      onClick={onClick}>
      <CardHeader
        avatar={<AccountAvatar account={account} />}
        action={<AccountActions account={account} reloadAccounts={reloadAccounts} demoActivated={demoActivated} />}
        title={account.accountName}
        subheader={account.templateName}
      />
      <CardContent>
        {account.active && (
          <Stack direction="row" mb={'5px'} spacing={'4px'} divider={<span>&bull;</span>}>
            <FilterDisplay filtersState={filtersState} profile="warn" />
            <FilterDisplay filtersState={filtersState} profile="alert" />
          </Stack>
        )}

        <Box pb={1}>
          <AccountStatusLabel account={account} />
        </Box>
        {account.subscription && (
          <ParaSmall>
            {account.subscription.subName} {account.subscription.isTrial && '(Trial)'}
          </ParaSmall>
        )}
      </CardContent>
    </Card>
  )
}
