import * as React from 'react'
import { IconButton, Menu, MenuItem, MoreVerticalIcon, Box } from '@papertrail/styleguide'
import { Subscription } from '../types'
import { useHistory } from 'react-router-dom'

type Props = {
  subscription: Subscription
}

/**
 * Actions menu for subscription shown on all active subscriptions
 * No extra permissions on menu items because only owners can see subscriptions
 */
export default function SubscriptionActions(props: Props) {
  const { subscription } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const history = useHistory()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  function navigateTo(route: string) {
    history.push(route)
  }
  if (subscription.active) {
    return (
      <Box
        onClick={(event) => {
          event.stopPropagation()
        }}>
        <IconButton
          data-testid="subscription-actions-button"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}>
          <MoreVerticalIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}>
          <MenuItem
            onClick={() => navigateTo(`/subscriptions/${subscription.id}/overview`)}
            data-testid="view-subscription-button">
            Settings
          </MenuItem>
          <MenuItem
            onClick={() => navigateTo(`/subscriptions/${subscription.id}/owners/new`)}
            data-testid="subscription-owner-button">
            Invite Owner
          </MenuItem>
        </Menu>
      </Box>
    )
  } else {
    return null
  }
}
